// import { defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
// import lazyComponent from 'public/src/services/lazyComponent/lazy_hydrated.js' // 组件懒加载
import StaticImage from '../components/static-image/index.vue'
import { hydrateWhenVisible } from '@shein-aidc/shein-lazy-hydration'

const componentsMap = {
  // 静态图片（单图、双图、三图）
  StaticImage,
  // StaticImage: defineAsyncComponent(() => import(/* webpackChunkName: "ccc-static-image" */'../components/static-image/index.vue')),
  // 图文组件
  TextEdit: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-text-edit" */'../components/TextEdit')),
  // 多文字区块
  MultiText: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-multi-text" */'../components/MultiText/MultiText.vue')),
  // 新闻文章列表组件
  NewsRoomArticle: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-text-list" */'../components/news-room/index.vue')),
  HorizontalCoupon: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-coupon-list" */'../components/coupon/CouponList')
  ),
  VerticalCoupon: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-coupon-list" */'../components/coupon/CouponList')
  ),
  HorizontalItems: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-horizontal-items" */'../components/HorizontalItems.vue')
  ),
  DailyNewItems: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-daily-new-items" */'../components/daily-new/index')
  ),
  // 左图右商品
  LeftPictrueItems: hydrateWhenVisible(
    () => import(/* webpackChunkName: "left-pic-items" */'../components/image-products/Index.vue')
  ),
  VerticalItems: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-vertical-items" */'../components/VerticalItems.vue')
  ),
  // 轮播图
  CarouselImage: hydrateWhenVisible(
    () => import(/* webpackChunkName: "carousel-image" */'../components/CarouselImage')
  ),
  // 横滑多图
  SideslipImage: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-sideslip-image" */'../components/SideslipImage.vue')
  ),
  // 闪购
  FlashSale: hydrateWhenVisible(() => import(/* webpackChunkName: "flash-sale" */'../components/flash-sale/Index.vue')),

  // 1/3组件
  OneThirdContainerComponent: hydrateWhenVisible(() => import(/* webpackChunkName: "one-third-component" */'../components/one-third-combine')),

  // 文字、图片锚点
  HorizontalAnchor: hydrateWhenVisible(
    () => import(/* webpackChunkName: "horizontal-anchor" */'../components/anchor/index')
  ),
  HorizontalAnchorImage: hydrateWhenVisible(
    () => import(/* webpackChunkName: "horizontal-anchor" */'../components/anchor/index')
  ),
  // 倒计时
  Countdown: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-countdown" */'../components/countdown/Countdown')
  ),
  // 排行榜入口
  RankingEntrance: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-ranking-entrance" */'../components/rank/RankingEntrance')
  ),
  // 排行榜
  RankingList: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-ranking-list" */'../components/rank/RankingList')
  ),
  // 日历
  Calendar: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-calendar" */'../components/calendar/Calendar.vue')
  ),
  // 推客券包组件
  NewUserPackage: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-newuser-package" */'../components/newuser-package/newuser-package.vue')
  ),
  // 视频组件
  Video: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-video" */'../components/video/index.vue')
  ),
  // 领积分组件
  Integral: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-integral" */'../components/integral/index')
  ),
  // 订阅组件
  Subscribe: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-subscribe" */'../components/subscribe/index.vue')
  ),
  // 历史最低价组件
  LowestPrice: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-lowest-price" */'../components/lowest-price/index.vue')
  ),
  // 意见收集组件
  Survey: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-survey" */'../components/survey/index.vue')
  ),
  // PK组件
  PK: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-pk" */'../components/pk/index.vue')
  ),
  ProductRecommend: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-product-recommend" */'../components/ProductRecommend.vue')
  ),
  CategoryRecommendDynamic: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-category-recommend" */'../components/CategoryRecommendDynamic/index.vue')),

  // 品牌推荐组件
  BrandRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-brand-recommend" */'../components/brand-recommend/index')),
  LookBook: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-look-book" */'../components/look-book/index')),

  // 店铺卡片列表组件
  StoreCardList: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-store-card-list" */'../components/store/card-list/index.vue')),
  // 店铺单卡片组件
  StoreSingleCard: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-store-single-card" */'../components/store/single-card/index.vue')),
  // 多店铺横滑组件
  StoreMultiSlide: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-store-multi-slide" */'../components/store/multi-slide/index.vue')),
  // 多店铺一店一品组件
  StoreMultiSlideOne: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-store-multi-slide-one" */'../components/store/multi-slide-one/index.vue')),
  // 多店铺圆角卡片组件
  StoreMultiSlideCard: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-store-multi-slide-card" */'../components/store/multi-slide-card/index.vue')),
  // 设计师组件
  SheinXList: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-shein-x-list" */'../components/shein-x-list/index.vue')),
  // 付费会员组件
  ClubPromotion: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-club-promotion" */'../components/prime/ClubPromotion')),
  // 设计师投票组件
  SheinXVote: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-shein-x-vote" */'../components/shein-x-vote/index.vue')),
  // 1/2组件（闪购、商品）
  HalfComponentContainer: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-one-second" */'../components/half/index')),


  // 不贵组件
  SuperDeals: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-store-category-recommend" */'../components/super-deals/SuperDeals.vue')),

  // 新闻板块组件
  NewsSection: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-news-section" */'../components/news-section/NewsSection.vue')
  ),
  // 转盘抽奖组件
  DrawSpin: hydrateWhenVisible(
    () => import(/* webpackChunkName: "ccc-draw-spin" */'../components/draw-spin/DrawSpin.vue')
  ),
  // 专题不贵组件
  ActiveSuperDeals: hydrateWhenVisible(() => import(/* webpackChunkName: "ccc-store-category-recommend" */'../components/active-super-deals/SuperDeals.vue')),
  // 专题类目分流器组件
  SbcSale: hydrateWhenVisible(() => import(/* webpackChunkName: "cccx-sbc-sale" */'../components/sbc-sale/index.vue')),
}

// const components = Object.entries(componentsMap).reduce((prev, cur) => {
//   prev[`${cur[0]}`] = lazyComponent({
//     componentFactory: cur[1],
//   })
//   if (['HorizontalAnchor', 'HorizontalAnchorImage'].includes(cur[0])) {
//     prev[`${cur[0]}`] = cur[1]
//   }
//   return prev
// }, {})
componentsMap['ClientOnly'] = ClientOnly
// componentsMap['LazyHydrationWrapper'] = LazyHydrationWrapper

export default componentsMap
